import React from "react";
import cuid from "cuid";
import { useMediaQuery, useTheme } from "@material-ui/core";

import EventCard from "./EventCard";

import { StyledEvents } from "./Events.styled";

import { eventContent } from "./events.content";

const Events = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledEvents>
      {eventContent.map((e) => {
        return (
          <EventCard
            key={cuid()}
            imgSrc={e.imgSrc}
            imgAlt={e.imgAlt}
            title={e.title}
            date={e.date}
            details={e.details}
          />
        );
      })}
    </StyledEvents>
  );
};

export default Events;
