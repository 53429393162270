import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { FancyHr } from "../FancyHr";

const EventCard = ({ imgSrc, imgAlt, title, date, details }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box p={isSmall ? 2 : 4}>
      <Grid container>
        <Grid xs={12} md={2}></Grid>
        <Grid xs={12} md={8}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src={imgSrc}
                alt={imgAlt}
                style={{ width: isSmall ? "100%" : "300px" }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h4">{title}</Typography>
              <Box mb={2}>
                <Typography>{date}</Typography>
              </Box>
              {details.map((e) => (
                <Typography paragraph>{e}</Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={2}></Grid>
      </Grid>
      <FancyHr center />
    </Box>
  );
};

EventCard.propTypes = {
  imgSrc: PropTypes.node,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  details: PropTypes.string,
};

export default EventCard;
