import ImgJazzByRiver from "../../images/events/jazz-by-river-01.webp";
import ImgGolfTournament from "../../images/events/golf-tournament-01.webp";
import ImgChristmasTea from "../../images/events/christmas-tea-01.webp";

const eventContent = [
  {
    imgSrc: ImgJazzByRiver,
    imgAlt: `jazz by the river`,
    title: `Jazz by the River`,
    date: `No date scheduled yet`,
    details: [
      "The Richard Allen Cultural Center and Museum is constantly evaluating COVID-19 re-opening schedules to determine the feasibility of holding its annual Jazz by the River event for 2020.",
      "The pictures are from last years event and we hope to see everyone there again this year. For more information about ticket prices or any other questions, please call: (913) 682-8772. ",
    ],
  },
  {
    imgSrc: ImgGolfTournament,
    imgAlt: `golf tournament`,
    title: `Golf Tournament`,
    date: `No date scheduled yet`,
    details: [
      "The 2020 Golf Tournament is CANCELLED due to COVID-19 re-opening schedule. Please consider supporting our virtual tutoring activities that are STILL taking place today!",
      "The Richard Allen Cultural Center and Museum held its annual golf tournament on May 5, 2019. The Richard Allen Cultural Center and Museum would like to thank everyone who volunteered for this event as well as congratulate all of the participants. ",
    ],
  },
  {
    imgSrc: ImgChristmasTea,
    imgAlt: `Christmas tea`,
    title: `Christmas Tea`,
    date: `No date scheduled yet`,
    details: [
      "The Richard Allen Cultural Center and Museum held its annual Christmas Tea event in December 2018. Again, we would like to thank everyone that volunteered to help with the event because without you this would never have been possible. ",
    ],
  },
];
export { eventContent };
